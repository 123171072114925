import { faBroom, faCloud, faDatabase, faDesktop, faLock, faPerson, faPhone } from '@fortawesome/free-solid-svg-icons';

export const ItSolutions = [
    {
        title: "Helpdesk",
        icon: faDesktop,
        content:
            <div>
                Onze professionele bemande helpdesk is binnen kantooruren voor onze klanten
                telefonisch bereikbaar en we hebben een storingsdienst die buiten de kantooruren
                voor storingen kan worden gebeld.
            </div>
    },
    {
        title: "Beheer & onderhoud",
        icon: faBroom,
        content:
            <div>
                Wij implementeren, beheren en onderhouden uw ICT omgeving met een persoonlijke
                en adviesgerichte aanpak. We zijn flexibel en nemen verantwoordelijkheid teneinde
                een stabiele, veilig en betrouwbare omgeving te waarborgen waarin een efficiënt en
                effectief informatiesysteem voor uw bedrijf de door u gewenste ondersteuning geeft.
                Kennis en vakmanschap in onderhoud van uw ICT infrastructuur.

                <h2>Kantooroplossingen ICT</h2>
                Door onze ruime ervaring met beheerssystemen en grotere of kleinere ICT
                infrastructuren, leveren we altijd maatwerk.

                <h2>Thuiswerkplekken</h2>
                Werkplekken die het mogelijk maken om op afstand te kunnen werken. We zorgen voor
                goede verbinding en hardware, of passen deze aan, zodat het optimaal werken wordt
                op afstand.

                <h2>Hardware</h2>
                Bijvoorbeeld: servers, PC’s, notebooks, routers, switches, firewalls of NAS.

                <h2>Software</h2>
                o.a. Windows en office pakketten maar ook bijvoorbeeld Excel spreadsheets op maat.
                Bovendien hebben we de mogelijkheid om onze Software Engineer te raadplegen
                teneinde een product op maat te kunnen leveren.
            </div>
    },
    {
        title: "Cloud oplossingen",
        icon: faCloud,
        content:
            <div>
                Cloud diensten zijn altijd en overal beschikbaar.
                <br /><br />
                We denken graag met u mee wat dit voor uw bedrijf kan betekenen of als u nog niet toe
                bent aan een definitieve overstap: ook hybride Cloud oplossingen behoren tot de
                mogelijkheden.
            </div>
    },
    {
        title: "Telefonie",
        icon: faPhone,
        content:
            <div>
                Bellen over een computernetwerk zoals bijvoorbeeld internet.Ook hierin hebben we
                een professionele, snelle en veilige oplossing voor uw bedrijf.
            </div>
    },
    {
        title: "Advies",
        icon: faPerson,
        content:
            <div>
                ICT Advies over uw bedrijf wordt in elk stadium desgewenst gegeven. ICT moet
                uiteindelijk een goed afgestemd en betrouwbaar geheel van processen en systemen
                vormen. Hiervoor is kennis en vakmanschap nodig waarover ons TOPteam beschikt. We maken
                graag een afspraak om ook u te adviseren over ICT binnen uw bedrijf.
                <br /><br />
                Samen met u bekijken we de mogelijkheden en wensen voor ICT binnen uw bedrijf.
                Deze wensen en mogelijkheden worden omgezet in een voorstel en/of ontwerp.
                Na een kennismakingsgesprek ontwerpen we voor u een veilige en betrouwbare ICT
                omgeving.
            </div>
    },
    {
        title: "Beveiliging",
        icon: faLock,
        content:
            <div>
                Antivirussoftware, encryptie, certificaten.
            </div>
    },
    {
        title: "Data opslag",
        icon: faDatabase,
        content:
            <div>
                Online Cloud storage bij Routit, Microsoft (oneDrive, Azure) Dropbox. Graag bekijken
                wij wat voor u de beste data opslag inhoudt.
            </div>
    },
]