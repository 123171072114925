import { NavLink } from "react-router-dom";
import TaLogo from '../components/TaLogo';
import '../../style/components/Header.scss';
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

export default function Header(_) {
    const sponsorBarRef = useRef(null);
    const hamburgerRef = useRef(null);
    const hamburgerMenuRef = useRef(null);

    const [scrolled, setScrolled] = useState(false);
    const [navFloating, setNavFloating] = useState(false);

    const scrollToTop = () => window.scroll({ top: 0, behavior: 'smooth' });
    const setScrolledTrue = () => setScrolled(true);

    function setLogoVisibility() {
        if (!scrolled) return;

        const sponsorBar = sponsorBarRef.current;
        setNavFloating(window.scrollY > (sponsorBar.offsetTop + sponsorBar.offsetHeight));
        setScrolled(false);
    }

    function toggleHamburger() {
        const hamburger = hamburgerRef.current;
        const hamburgerMenu = hamburgerMenuRef.current;

        hamburger.classList.toggle('open');
        hamburgerMenu.classList.toggle('open');
    }

    function closeHamburger() {
        const hamburger = hamburgerRef.current;
        const hamburgerMenu = hamburgerMenuRef.current;

        hamburger.classList.remove('open');
        hamburgerMenu.classList.remove('open');
    }

    useEffect(() => {
        window.addEventListener('scroll', setScrolledTrue);
        const interval = setInterval(setLogoVisibility, 250);

        return () => {
            window.removeEventListener('scroll', setScrolledTrue);
            clearInterval(interval);
        };
    })

    return (
        <>
            <div ref={sponsorBarRef} className="logo-header">
                <NavLink to="/" onClick={scrollToTop}><TaLogo className="ta-logo" /></NavLink>
            </div>
            <nav className={navFloating ? "floating" : ""}>
                <div className="regular-menu">
                    <div className="left">
                        <NavLink to="/it-solutions"><span className="accented">iT</span>-oplossingen</NavLink>
                    </div>
                    <div className="center">
                        <NavLink to="/" onClick={() => { scrollToTop(); closeHamburger(); }}><TaLogo className="ta-logo" /></NavLink>
                    </div>
                    <div className="right">
                        <NavLink to="/top-team">Top-team</NavLink>
                        <NavLink to="/contact">Contact</NavLink>
                    </div>
                    <div ref={hamburgerRef} onClick={toggleHamburger} className="hamburger">
                        <FontAwesomeIcon icon={faBars} className="open-btn" />
                        <FontAwesomeIcon icon={faTimes} className="close-btn" />
                    </div>
                </div>
                <div ref={hamburgerMenuRef} className="hamburger-menu">
                    <NavLink to="/it-solutions" onClick={closeHamburger}><span className="accented">iT</span>-oplossingen</NavLink>
                    <NavLink to="/top-team" onClick={closeHamburger}>Top-team</NavLink>
                    <NavLink to="/contact" onClick={closeHamburger}>Contact</NavLink>
                </div>
            </nav>
        </>
    );
}