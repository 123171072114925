import { useEffect } from 'react';
import '../../style/pages/Contact.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocationDot, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faAndroid, faApple, faWindows } from '@fortawesome/free-brands-svg-icons';

export default function Home() {
    useEffect(() => { document.title = 'Topassist - Contact'; }, []);

    return(
        <div className="contact-page">
            <div className="map-container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2480.037617517203!2d4.464756476991301!3d51.56754390630741!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c417e442a369bb%3A0xb368ecf4bff7e0c9!2sTOPsystems!5e0!3m2!1sen!2snl!4v1718463261432!5m2!1sen!2snl" title="map" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
            <div className="content">
                <div className="contact-information">
                    <h1>informatie</h1>
                    <div>
                        <div>
                            <a href="tel:0165 745 295">
                                <span><FontAwesomeIcon icon={faPhone} /></span>
                                <span>0165-745295</span>
                            </a>
                            <a href="mailto:info@pi-ict.nl">
                                <span><FontAwesomeIcon icon={faEnvelope} /></span>
                                <span>info@pi-ict.nl</span>
                            </a>
                            <a href="https://maps.app.goo.gl/zuAMGLuQkb3hg1Jz5" target="_blank" rel="noreferrer">
                                <span><FontAwesomeIcon icon={faLocationDot} /></span>
                                <span>Argon 15o<br/>4751 XC Oud Gastel</span>
                            </a>
                        </div>
                        <div>
                            <span>
                                <span>KvK Breda:</span>
                                <span>20087084</span>
                            </span>
                            <span>
                                <span>BTW:</span>
                                <span>NL8091.70.486.B01</span>
                            </span>
                            <span>
                                <span>Bank:</span>
                                <span>NL25 ABNA 0518 8187 72</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="teamviewer">
                    <h1>Teamviewer</h1>
                    <h2>Desktop</h2>
                    <div className="buttons">
                        <a href="/downloads/windows_support.exe">
                            <FontAwesomeIcon icon={faWindows} />
                            <span>Windows download</span>
                        </a>
                        <a href="/downloads/windows_support.exe">
                            <FontAwesomeIcon icon={faApple} />
                            <span>MacOS download</span>
                        </a>
                    </div>
                    <h2>Mobiel</h2>
                    <div className="buttons">
                        <a href="/downloads/windows_support.exe">
                            <FontAwesomeIcon icon={faAndroid} />
                            <span>Android download</span>
                        </a>
                        <a href="/downloads/windows_support.exe">
                            <FontAwesomeIcon icon={faApple} />
                            <span>iOS download</span>
                        </a>
                    </div>
                </div>
                <div className="vacancies">
                    <h1>Vacatures</h1>
                    <div>
                        <h2>Amateur werknemer</h2>
                        <p>Werk verrichten en betaald krijgen! 3950 euro per maand!</p>
                    </div>
                    <div>
                        <h2>Junior werknemer</h2>
                        <p>Werk verrichten en betaald krijgen! 3975 euro per maand!</p>
                    </div>
                    <div>
                        <h2>Senior werknemer</h2>
                        <p>Werk verrichten en betaald krijgen! 4000 euro per maand!</p>
                    </div>
                </div>
                <div className="form">
                    <h1>Contact formulier</h1>
                    <div className="inputs">
                        <label htmlFor="name"><h2>Naam</h2></label>
                        <input type="text" id="name" />
                        <label htmlFor="mail"><h2>E-mail</h2></label>
                        <input type="email" id="mail" />
                        <label htmlFor="phone"><h2>Telefoonnummer</h2></label>
                        <input type="tel" id="phone" />
                        <label htmlFor="subject"><h2>Onderwerp</h2></label>
                        <input type="text" id="subject" />
                        <label htmlFor="content"><h2>Inhoud</h2></label>
                        <textarea id="content" />
                        <button><FontAwesomeIcon icon={faPaperPlane} />Sturen</button>
                    </div>
                </div>
            </div>
        </div>
    )
}