import { useEffect } from 'react';
import '../../style/pages/Home.scss';
import NavIconRect from '../components/NavIconRect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItSolutions } from '../../resources/it-solutions';

export default function Home() {
    useEffect(() => { document.title = 'Topassist - Home'; }, []);

    return(
        <div className="home-page">
            <div className="eye-catcher-container">
                <video autoPlay loop>
                    <source src="/images/background.mp4" type="video/mp4"/>
                </video>
            </div>
            <div className="content">
                <div className="objective">
                    <h1>Onze missie</h1>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas cursus ligula porttitor,
                        venenatis mauris vel, condimentum felis. Aliquam posuere, lectus ac molestie lobortis, magna
                        turpis consectetur mauris, non sollicitudin lorem tortor ac dolor. Vestibulum nunc metus,
                        ullamcorper a justo ut, pharetra rhoncus lectus. Nullam quis convallis dui. Cras vestibulum
                        auctor lorem, vitae sodales velit. Vivamus cursus laoreet rhoncus. Ut vitae tristique purus.
                        Vestibulum interdum leo vulputate finibus tristique. Sed aliquam quam vitae augue ornare, eu
                        rutrum nulla venenatis. Donec vel nulla aliquam dui tempus auctor. Morbi non pulvinar odio.
                        Integer tempor ac erat ultricies sodales.<br /><br />

                        Sed et sodales tellus, nec sollicitudin massa. Nulla eget orci eget purus tincidunt
                        scelerisque ac vitae massa. Aliquam vitae libero odio. Morbi nibh velit, ullamcorper non velit
                        vel, efficitur sagittis sem. Praesent eros est, dignissim eu orci eget, maximus volutpat velit.
                        Aliquam condimentum diam nunc, quis vestibulum ex consectetur a. Phasellus ligula erat, rhoncus
                        ut volutpat porta, vestibulum vel augue. Maecenas vitae dui tempus, blandit dui eu, euismod
                        dolor.<br /><br />

                        Suspendisse ante sem, porta sed ultrices nec, fringilla sit amet eros. Aliquam vitae ex
                        egestas, cursus tellus eu, mattis justo. Donec egestas libero non turpis volutpat sollicitudin.
                        Sed eu ante efficitur, porttitor massa a, interdum est. Nullam blandit consequat lacus eget
                        lobortis. Aliquam tellus magna, porttitor eget dapibus in, molestie in quam. Ut id iaculis lacus,
                         at consequat urna.
                    </p>
                </div>
                <div className="it-solutions">
                    <h1><span className="accented">iT</span>-oplossingen</h1>
                    <h3>Een paar services die wij bieden vind je hieronder, druk op een knop om meer informatie te zien</h3>
                    <div className="icons">
                        {
                            ItSolutions.map((s, i) =>
                                <NavIconRect
                                    to={`/it-solutions?opened=${i}`}
                                    icon={<FontAwesomeIcon icon={s.icon} />}
                                    text={s.title}
                                    key={s.title} />)
                        }
                    </div>
                </div>
                <div className="partners">
                    <h1>Partners</h1>
                    <p>Hier komen partners.</p>
                </div>
            </div>
        </div>
    )
}