import '../../style/components/NavIconRect.scss';
import { NavLink } from "react-router-dom";

export default function NavIconRect(props) {
    return(
        <NavLink to={props.to} className="nav-icon-rect">
            {props.icon}
            <span>{props.text}</span>
        </NavLink>
    );
}