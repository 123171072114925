import { NavLink } from "react-router-dom";
import TaLogo from '../components/TaLogo';
import '../../style/components/Footer.scss';

export default function Footer(_) {
    const scrollToTop = () => window.scroll({ top: 0, behavior: 'smooth' });

    return(
        <footer>
            <div className="left">
                <div className="links">
                    <NavLink to="/" onClick={scrollToTop}><TaLogo className="ta-logo" /></NavLink>
                    <NavLink to="/it-solutions"><span className="accented">iT</span>-oplossingen</NavLink>
                    <NavLink to="/top-team">Top-team</NavLink>
                    <NavLink to="/contact">Contact</NavLink>
                </div>
            </div>
            <div className="center">
                <div>
                    <span>Argon 15o, 4751 XC</span>
                    <span>Oud Gastel</span>
                </div>
                <div>
                    <a href="tel:0165 745 295">0165-745295</a>
                    <a href="mailto:info@pi-ict.nl">info@pi-ict.nl</a>
                </div>
                <div>
                    <span>Maandag t/m vrijdag</span>
                    <span>08:30 - 17:00 uur</span>
                </div>
            </div>
            <div className="right">
                <div className="support-downloads">
                    <span>Support op afstand</span>
                    <div className="buttons">
                        <a href="/downloads/windows_support.exe">Windows</a>
                        <hr/>
                        <a href="/downloads/macos_support.exe">MacOS</a>
                    </div>
                </div>
            </div>
        </footer>
    )
}